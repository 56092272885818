import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import { Link } from 'react-scroll';
import AddImage1 from '../../assets/img/add/273x397.jpg';
import AddImage2 from '../../assets/img/add/174x293.jpg';
import AddImage3 from '../../assets/img/add/116x130.jpg';
import AddImage4 from '../../assets/img/add/174x198.jpg';

export default function Project() {
  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    }
  })

  function isBottom(el) {
    return el?.getBoundingClientRect().bottom - 200 <= window.innerHeight;
  }

  const trackScrolling = () => {
    const wrappedElement = document.getElementById('projectImagesContainer');
    if (isBottom(wrappedElement)) {
      console.log('header bottom reached');
      document.getElementById('projectImages').classList.add('animate')
      document.removeEventListener('scroll', trackScrolling);
    }
  };
  return (
    <Wrapper id="project">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter" id="projectImagesContainer">
            <AddLeft>
              {/*<h4 className="font15 semiBold">A few words about company</h4>*/}
              <h2 className="font40 extraBold">О проекте</h2>
              <p className="font14">
                Комплекс Yas Golf Collection представляет собой комплекс курортного типа от Aldar Properties, расположенный на острове Яс. Комплекс с видом на поле для гольфа Yas Links и мангровые заросли Абу-Даби.
                <br/><br/>Проект включает в себя квартиры и дуплексы, распределенные по 5 кластерам. В первом выпуске представлено 519 объектов недвижимости формата Souq Furnished Apartments — это полностью меблированные студии и апартаменты с 1 спальней с балконами в стиле Ромео и Джульетты, расположенные в 4 зданиях в районе Сук. Будущие владельцы смогут выбрать дизайн в одном из 3 цветовых решений, и из каждой резиденции доступны вид на мир Феррари, вид на рынок, вид на сообщество и частичный вид на море.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                <div style={{ width: "190px" }}>
                  <Link to="contact" smooth={true}><FullButton title="Оставить заявку" /></Link>
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <Link to="contact" smooth={true}>
                    <FullButton title="Обратная связь" border />
                  </Link>
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight id="projectImages">
              <AddRightInner>
                <div className="flexNullCenter">
                  <AddImgWrapp1 className="flexCenter">
                    <img src={AddImage1} alt="office" />
                  </AddImgWrapp1>
                  <AddImgWrapp2>
                    <img src={AddImage2} alt="office" />
                  </AddImgWrapp2>
                </div>
                <div className="flexNullCenter">
                  <AddImgWrapp3>
                    <img src={AddImage3} alt="office" />
                  </AddImgWrapp3>
                  <AddImgWrapp4>
                    <img src={AddImage4} alt="office" />
                  </AddImgWrapp4>
                </div>
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Почему Yas Golf Collection?</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Образ жизни"
                text={
                  <span>
                    В Yas Golf Collection создана атмосфера расслабленного морского курорта, которую подчеркивает изысканная архитектура зданий в средиземноморском стиле. Жители комплекса смогут с комфортом проводить время в лаундж-зонах с различными удобствами и изысканным дизайнерским интерьером. Благодаря широкому спектру услуг, предоставляемых на территории комьюнити, вы можете ощутить себя желанным гостем пятизвездочного отеля.
                    <br/><br/>На территории комьюнити будет доступен широкий выбор удобств для поддержания самых разных стилей жизни, от занятий спортом в ультрасовременных спортивных залах до шопинга в брендовых бутиках.
                  </span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Преимущества ЖК"
                text={
                  <>
                    Новый комплекс – это идеальное место для тех, кто хочет жить на морском побережье. Все жители комьюнити имеют прямой доступ к протяженному пляжу Yas Beach с белым песком и кристально чистой водой.
                    <br/><br/>Также на морской горизонт можно любоваться, отдыхая в комфортных ландшафтных садах на территории комплекса.
                    <br/><br/>Немаловажным преимуществом Yas Golf Collection является его удачное расположение рядом со знаменитым чемпионским полем для гольфа Yas Links, где работает академия гольфа, клуб с различными услугами, есть бассейн и многие другие удобства. Также недалеко от комплекса располагается гоночная трасса и парки аттракционов.
                  </>
                }
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Завершение строительства"
                text={
                  <span>
                    Строительство Yas Golf Collection начнется в I квартале 2023 года, а ориентировочная дата сдачи объекта в эксплуатацию назначена на 2025 год.
                    <br/><br/>Так как разработкой проекта занимается крупный девелопер Aldar Properties, имеющий прекрасную репутацию и внушительное портфолио, покупатели недвижимости могут смело рассчитывать на четкое соблюдение сроков строительства.
                    <br/><br/>Жилые здания комплекса и окружающие их общественные зоны будут соответствовать высоким оценкам рейтинговой системы Pearl Rating System, что говорит о лучших стандартах качества строительства.
                  </span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Недвижимость в Yas Golf Collection для инвестиций"
                text={
                  <span>
                    В новом комплексе будут представлены разные варианты недвижимости, в том числе квартиры, которые идеально подойдут для инвестирования. Студии и апартаменты с одной спальней со стильным дизайном и полной меблировкой станут отличным вариантом для того, чтобы сдавать их в краткосрочную или долгосрочную аренду. Так как резиденции находятся в прибрежном районе, рядом с привлекательными для туристов и местных жителей инфраструктурными объектами, здесь всегда будет высокий спрос среди арендаторов.
                    <br/><br/>Предполагается, что рентабельность инвестиций в новый проект будет составлять до 8,1%, что является высоким показателем для рынка недвижимости ОАЭ.
                  </span>
                }
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-12 flexCenter flex x100" style={{marginTop: 30}}>
              <Link to="contact" smooth={true}><FullButton title="Оставить заявку" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  #projectImages {
    display: none;
  }
  #projectImages.animate {
    display: block;
    animation-duration: 0.5s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  }

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

